<template>
	<v-card height="100vh" width="100%" flat tile class="card-container" :img="image">
		<v-container fluid class="container-login-main" >
			<v-row no-gutters dense class="fill-height" justify="center">
                <v-col align-self="center" cols="6" class="d-none d-lg-block">
                    
                    <div flat height="500" rounded="0" class="left-card" color="#cbcbcb" >
                        <div class="left-container">
                            <v-img :src="imtes" alt="" class="left-image" contain ></v-img>
                            <v-img :src="logo1" width="200" class="mx-auto mt-3"></v-img>
                        </div>
                    </div>
                </v-col>
				<v-col align-self="center" sm="12" md="12" lg="6" >
					<div class="right-card" rounded="0" flat height="500" color="#1e1e1e">
                        <div class="right-container">
                            <div class="form-container">
                                <div class="form-title-container">
                                    <span>bienvenido</span>
                                </div>

                                <!-- form -->
                                <v-form ref="form" v-model="valid" lazy-validation v-if="login">
                                    <v-row>
                                        <v-col class="">
                                            <p class="red--text text-center caption">{{errorText}}</p>
                                            <label for="" class="form-label">Correo Electronico</label>
                                            <v-text-field
                                                color="primary"
                                                hide-details
                                                v-model="user"
                                                filled
                                                data-cy="login-input-email"
                                                v-on:keyup.native.enter="loginApi"
                                                class=" input login-input"
                                            >
                                                
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="">
                                            <label for="" class="form-label">Contraseña</label>
                                            <v-text-field
                                                color="primary"
                                                hide-details
                                                v-model="password"
                                                filled
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show ? 'text' : 'password'"
                                                label=""
                                                data-cy="login-input-password"
                                                @click:append="show = !show"
                                                v-on:keyup.native.enter="loginApi"
                                                class="input login-input password-login-input"
                                            >
                                               

                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <v-col class="py-10 text-center">
                                            <v-btn data-cy="login-button-start-session" :loading="loginIn" color="loginBtn" block light depressed @click="loginApi" class="form__btn">
                                                <div class="btn__text" >
                                                    Iniciar sesión
                                                </div>
                                            </v-btn>
                                            <!-- Olvidé mi contraseña link -->
                                            <!-- <div class="my-9 form__btn-link">
                                                <a href="/login" class="">&nbsp; ¿Olvidaste tu contraseña?</a>
                                            </div> -->
                                        </v-col>
                                    </v-row>


                                </v-form>
                            </div>
                            <v-img :src="logo" width="150" class="mx-auto mt-9"></v-img>
                        </div>
                    </div>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
		
	
</template>

<script>
// import image from '../../assets/backgroundLogin.png';
import store from '../store';
import TokenManager from '../helpers/TokenManager';
import logo from '../assets/ynlogo.png';
import logo1 from '../assets/chlogo.png';
import background from '../assets/back_3.png'
import imgtest from '../assets/side_login1.png';
import VueGatesPlugin from '../plugins/vue-gates';
export default {
  components: {  },
	name: 'Login',
	data: () => ({
        image: background,
        imtes: imgtest,
		logo: logo,
        logo1: logo1,
		login: true,
		loginIn: false,
		valid: false,
		show: false,
		rememberMe: false,
		user: '',
		password: '',
		loginError: false,
		errorText: '',
		rules: {
			required: value => !!value || 'Correo requerido',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Correo no válido'
			},
			password: [
				v => !!v || 'Contraseña requerida'
			],
		},

	}),
	methods: {
		loginApi(){
			if(this.$refs.form.validate()){
				this.loginIn = true
				this.loginError = false
				//const tokenManager = new TokenManager();

				this.$api.auth.login(this.user, this.password)
				.then((response) =>{
					
					console.log(response)                
					if(response.status == 200 || response.status == 201){
						const type = 'Bearer'
						const token = response.data.access_token
						TokenManager.setToken(type+' '+token);
						this.$api.auth.reloadToken();
						//localStorage.setItem('access_token_rrhh', type+' '+token)
						store.state.loggedIn = false;
						// store.state.layout = 'dashboard';
                        store.state.userData = response.data;
                        store.state.userData = {...store.state.userData, role: {name: "user-role", permissions: response.data.permissions}};
                        store.state.loggedUsername = response.data.name??"U";
						// store.state.userData.role.permissions = response.data;
                        store.state.layout = 'dashboard';
                        VueGatesPlugin.init();
						
                        this.redirect();
					}else{
						TokenManager.removeToken();
						console.log('si llego')
						this.loginError = true
						switch (response.data.error) {
							case 'invalid_grant':
								this.errorText = 'Usuario y/o contraseña incorrectos'
								break;
							default:
								this.errorText = 'Error en servidor. Intente nuevamente.'
								break;
						}
					}
				})
				.catch((error) =>{
					TokenManager.removeToken();
					console.log(error, "error desde login");
					this.loginError = true
					if (error.response) {
						if(error.response.status == 401 || error.response.status == 400){
							this.errorText = 'Usuario y/o contraseña incorrectos';
							return;
						}
					}
					this.errorText = 'Error en servidor. Intente nuevamente.'
				})
				.finally(() => {
					this.loginIn = false
				});
				
			}
		},
        getUserData()
        {
            this.$api.auth.getPermissions()
				.then((response) =>{
					
					console.log(response)                
					if(response.status == 200 || response.status == 201){
						store.state.userData = {...store.state.userData, role: {name: "user-role", permissions: response.data}};
						// store.state.userData.role.permissions = response.data;
                        store.state.layout = 'dashboard';
                        VueGatesPlugin.init();
						
                        this.redirect();
					}else{
						TokenManager.removeToken();
						console.log('si llego')
						this.loginError = true
						switch (response.data.error) {
							case 'invalid_grant':
								this.errorText = 'Usuario y/o contraseña incorrectos'
								break;
							default:
								this.errorText = 'Error en servidor. Intente nuevamente.'
								break;
						}
					}
				})
				.catch((error) =>{
					TokenManager.removeToken();
					console.log(error, "error desde login");
					this.loginError = true
					if (error.response) {
						if(error.response.status == 401 || error.response.status == 400){
							this.errorText = 'Usuario y/o contraseña incorrectos';
							return;
						}
					}
					this.errorText = 'Error en servidor. Intente nuevamente.'
				})
				.finally(() => {
					this.loginIn = false
				});
        },
        redirect() {
            this.$router.push({ name: "Home" }).catch(() => {});
            
        },
	}
}
</script>

<style lang="scss">
	// .v-text-field--outlined fieldset {
	// 	color: red !important;
	// }
    .container-login-main{
        padding: 0 16%;

        @media (max-width: 700px) {
            padding: 0 1%;
        }
    }
    .left-container{
        padding: 140px 0;
        background-color: #1e1e1e;
        height: calc(70vh + 1px);

        border-radius: 10% 0% 10% 10% !important;

        @media (max-height: 745px) {
            height: calc(80vh + 1px);
        }
    }
    .left-card{
        background-color: #cbcbcb;
        height: 70vh;
        border-radius: 11% 0% 0% 11%;

        @media (max-height: 745px) {
            height: 80vh !important;
        }

        
    }
    .left-image{
        
    }

    .right-container{
        padding: 1px 0;
        background-color: #cbcbcb;
        height: inherit;
        border-radius: 10% 10% 10% 0% !important;

        @media (max-width: 1263px) {
            border-radius: 10% 10% 10% 10% !important;
        }
    }
    .right-card{
        background-color: #1e1e1e;
        height: 70vh;
        border-radius: 0% 11% 11% 0%;

        @media (max-width: 1263px) {
            background-color: transparent;
        }

        @media (max-height: 745px) {
            height: (80vh);
        }
    }

    .form-container::-webkit-scrollbar {
        display: none;
    }

    .form-container{
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        overflow: auto;
        
        background-color: #fff;
        height: calc(70vh - 18vh);
        margin: 85px 85px 10px 85px;
        padding: 20px 80px ;
        border-radius: 20px;

        @media (max-width: 1820px) {
            padding: 20px 40px;
            margin: 85px 65px 10px 65px;
        }

        @media (max-width: 1473px) {
            padding: 20px 20px;
            margin: 85px 45px 10px 45px;
        }

        @media (max-height: 745px) {
            height: calc(80vh - 18vh);
            margin: 55px 45px 10px 45px;
        }

        
    }

    .form-label{
        color: #7a7878;
        font-size: 13px;
        font-weight: bold;
        padding-left: 19px;
    }

    .login-input.v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none !important; 
    }
    .login-input.v-text-field > .v-input__control > .v-input__slot {
        border-style: none !important; 
        min-height: 40px !important;
       
    }
    .login-input input{
        text-transform: none !important;
    }

    .form-title-container{
       
        text-align: center;
        font-weight: bold;
        font-size: 2.7rem;
        color: var(--v-primary-base);
        margin: 20px 0 10px 0px;

        @media (max-width: 1863px) {
            /* … */
            font-size: 2.3rem;
        }
    }

    .login-input.v-text-field > .v-input__control > .v-input__slot{
        // border: solid 0px black !important;
    }

    .login-input.v-text-field > .v-input__control > .v-input__slot input{
    
      margin-top: 0px !important;
      padding: 0;
    }

    .v-text-field--filled fieldset {
        color: transparent !important;
    }


	.input{
		border-radius: 14px;
		&__label{
			color: var(--v-loginText-base)
		}
	}
	

	.btn{
		&__text{
			font-size: 1.0625rem;
			font-weight: bold;
			color: var(--v-primary-base);
			text-transform: uppercase;
		}
	}

	.form{

		&__full-container{
			background-color: rgba(0, 0, 0, 0.100) !important;
			z-index: 1;
			overflow: hidden;
		}
		&__logo-container{
			min-height: 12.5rem;
		}

		&__btn{

			border-radius: 0.75rem !important;
		
			&-link{
			
			
				> a{
					text-decoration: none;
					color: #7a7878 !important;
                    font-size: 15px;
                    font-weight: bold;
				}
			}
		}
	}

	.container{
		height: inherit;
	}

	.card-container{
		overflow: auto;
	}

	.login-container{

		@media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
			transform: scale(1.2);
			transform-origin: 1 0;
		}
	}

    .password-login-input {
        
    
        .v-input__append-inner{
        
            margin-top: 0;
            align-self: center;
        }
    }

	

	
</style>